/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

const mock = [
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/ClaimProcess1.jpeg',
    description:
      "FG Life understands that a claim incident can be daunting which is why we have implemented an internal in-depth claim process in order for us to effectively offer end-to-end assistance in your claim process. As part of our service to you as our clients, our dedicated claims team endeavors to assist and guide you in your claim process every step of the way. We handle your claim from start to finish, providing continuity, expertise, and advocating on your behalf with the providers. We'll not only save you time, but strive to make it as simple and hassle-free as possible for you.",
    desc: '',
    title: '',
    tags: [],
    author: {
      name: 'Clara Bertoletti',
      avatar: 'https://assets.maccarianagency.com/avatars/img2.jpg',
    },
    date: '04 Aug',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/MakeClaim.jpeg',
    description:
      'Claim incident notification can be done either verbally by contacting our claims team over the phone in which case our friendly claims handler will discuss the claim incident with you and advise you of the next steps or alternatively a claim incident notification can be done via email to the claims team. A friendly claims team handler will then contact you and advise you of the next steps.',
    desc: '',
    title: 'Claim Incident Notification',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/FileClaim.jpeg',
    description:
      'A claim form will be sent to you with concise instructions and relevant requirements for completion as well as contact details for our claims team should you have any queries or require assistance in completing the claim form. Once you have returned the completed claim form back to our dedicated claims team, we will submit your claim to the relevant insurance provider. You have the option of submitting a claim directly through to the insurance provider skipping us the broker – FG Life. In this instance unless you or the insurance provider notifies our claims team of this action, there is no way for our claims team to follow up on nor will we be aware that a claim exists. Should we however be made aware, then the claims process will continue as stipulated in this document.',
    desc: '',
    title: 'Completion of Claim Form',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/ClaimSettlement.jpeg',
    description:
      'Settlement offers are advised towards the end of the claim process. This will generally include a breakdown of the offer. We will always enquire as to your acceptance of the settlement offer and we will advocate on your behalf with the insurance providers for the best possible outcome.',
    desc: '',
    title: 'Claim Settlements and Finalization',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/FollowUp.jpeg',
    description:
      'Claim follow ups are actioned throughout a particular claim’s lifespan. Follow ups will always include claim acknowledgement, claim reference number and claim status. Requirement requests, status update requests and or general updates are attended to as promptly as possible.',
    desc: '',
    title: 'Claim Follow Up',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
];

const PopularNews = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4} bgcolor={theme.palette.alternate.main}>
        <Typography
          variant="h4"
          data-aos={'fade-up'}
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Terms & Conditions
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          By accessing the FG Life Website/s (“Website”), you agree to be bound
          by the terms and conditions set out below. The domain name for the
          Website is owned by FG Life. The Website/s, content and information
          available through the Website/s are owned and operated by FG Life.
        </Typography>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          <br />
          <br />
          FG Life has taken all reasonable care to ensure the accuracy of the
          information available on or through the Website. However, it is of a
          general nature and does not take into account any individual’s
          particular circumstances. FG Life makes no warranty, express or
          implied for accuracy, correctness, completeness or use of information
          that is available on or through the Website.
        </Typography>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          <br />
          <br />
          FG Life Services will take action to correct any error or inaccuracy
          which is brought to its attention, within a reasonable time.
        </Typography>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          <br />
          <br />
          FG Life may amend these terms of use from time to time so you should
          check and read these terms of use regularly. By continuing to use this
          site after any such amendment, you are deemed to have agreed to the
          amended terms of use.
        </Typography>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          <br />
          <br />
          This site and content has been prepared in accordance with the
          requirements of New Zealand law. These terms and any matters or
          disputes connected with this site will be governed by New Zealand laws
          and will be dealt with by New Zealand courts.
        </Typography>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          <br />
          <br />
        </Typography>
      </Box>
    </Box>
  );
};

export default PopularNews;
